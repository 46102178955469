export const requirement = [
    {
        value: 'Uygulama için minimum ekran boyutu: 13 inç önerilen: 15 inç.',
        nav: 'quadro-tus'
    },
    {
        value: 'Uygulama için minimum ekran çözünürlüğü: 1024*768 Önerilen: 1920 * 1080.',
        nav: 'quadro-tus'
    },
    {
        value: 'Uygulama için minimum: 4 GB RAM Önerilen: 8 GB.',
        nav: 'quadro-tus'
    },  
    {
        value: 'Tüm uygulamalar için minimum: 16 MB internet Önerilen: 32 MB.',
        nav: 'quadro-tus'
    },
    {
        value: 'Windows uygulama için önerilen işletim sistemi: Windows 10 ve üzeri sürüm.',
        nav: 'quadro-tus'
    },
    {
        value: 'Mac uygulama için önerilen işletim sistemi: Bigsur ve üzeri sürüm (Bigsur, Ventura, Monterey, Somona).',
        nav: 'quadro-tus'
    },
    {
        value: 'Mobil uygulamalar için en düşük sürüm Android: 5.0 ve sonrası Apple: iOS 13 ve sonrası.',
        nav: 'quadro-tus'
    },
]
import Navbar from "./components/navbar/Navbar";
import "./styles/global.scss";
import MasterLayout from "./layout/MasterLayout";
import SecondaryLayout from "./layout/SecondaryLayout";
import Header from "./components/header/Header";
import Package from "./components/package/Package";
import Application from "./components/application/Application";
// import Promotion from "./components/promotion/Promotion";
// import Video from "./components/video/Video";
import Advantage from "./components/advantage/Advantage";
import Afterdigi from "./components/afterDigi/Afterdigi";
import Duration from "./components/duration/Duration.jsx";
import Requirement from "./components/requirement/Requirement";
import Footer from "./components/footer/Footer";
import { useState, useReducer } from "react";
import SelectType from "./components/selectType/SelectType";
import { PackageReducer } from "./reducer/PackageReducer.jsx";
import Faq from "./components/faq/Faq";
import Popup from "./components/popup/Popup";
import { tawk } from "./liveChat";
import { GeneralContext } from "./context/generalContext";
import StartedPage from "./components/start/StartedPage";
import Chat from "./components/chat/Chat";

function App() {

  // tawk();

  const [popupUrl, setPopupUrl] = useState(null);
  const [situation, setSituation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useReducer(PackageReducer, {
    tus: false,
    dus: false,
    ydus: false
  });
  const providerObject = {
    popup: [isOpen, setIsOpen],
    popupURL: [popupUrl, setPopupUrl],
    packageCategory: state,
  }
  return (
    <>
      {
        situation ||
        // <SelectType dispatch={dispatch} setSituation={setSituation} />
        <StartedPage dispatch={dispatch} setSituation={setSituation} />
      }
      {
        situation &&
        <GeneralContext.Provider value={providerObject}>
          <Header state={state} />
          <Navbar setSituation={setSituation} />

          <MasterLayout >
            <Package state={state} setIsOpen={setIsOpen} />
          </MasterLayout >

          {/* <SecondaryLayout>
            <Promotion />
          </SecondaryLayout> */}

          {/* <MasterLayout>
            <Video />
          </MasterLayout > */}

          {/* <SecondaryLayout>
            <Advantage />
          </SecondaryLayout> */}

          {/* <MasterLayout>
            <Afterdigi />
          </MasterLayout > */}

          <SecondaryLayout>
            <Duration />
          </SecondaryLayout>

          <MasterLayout>
            <Faq />
          </MasterLayout >
          <Requirement />

          <Footer />
          <Application />

          <Popup />


          <Chat />

        </GeneralContext.Provider>
      }

    </>
  );
}

export default App;

export function PackageReducer(state, action) {
    switch (action.type) {
        case 'tus':
            return { tus: true, dus: false, ydus: false };
        case 'dus':
            return { tus: false, dus: true, ydus: false };
        case 'ydus':
            return { tus: false, dus: false, ydus: true };
        default:
            throw new Error();
    }
}

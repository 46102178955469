import React from 'react'
import { useState } from "react";
import { motion } from "framer-motion";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 70px, rgba(0,0,0,1) 70px, rgba(0,0,0,1) 70px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 70px)`;

export default function SecondaryLayout({ children }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    return (
        <motion.div
            onLoad={() => setIsLoaded(true)}
            initial={false}
            animate={
                isLoaded && isInView
                    ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
            }
            transition={{ duration: 1, delay: .5 }}
            viewport={{ once: true }}
            onViewportEnter={() => setIsInView(true)}>
            {children}
        </motion.div>
    )
}

import React, { useRef } from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { motion } from "framer-motion"
export default function StepTwo({ setPassStepThree, setSelectLesson }) {
    const ref = useRef(null);

    const takeValue = () => {
        const selectedTime = ref.current.innerText;
        setSelectLesson((prevList => ({ ...prevList, selectedTime })))
    }

    return (
        <motion.div initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}>
            <p className='select-time'>Lütfen günlük ortalama çalışma sürenizi (saat) seçiniz</p>
            <Box sx={{ width: 700 }}>
                <Slider ref={ref}
                    defaultValue={8}
                    valueLabelDisplay="on"
                    step={1}
                    marks
                    min={2}
                    max={14}
                    onChange={() => setPassStepThree(false)}
                />
            </Box>
            {/* <div className="time-type">saat</div> */}
            <div className='duration__button'>
                <button onClick={() => {
                    setPassStepThree(true);
                    takeValue();
                }}>
                    Hesapla
                </button>
            </div>
        </motion.div>
    )
}

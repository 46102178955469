import React from 'react';
import './styles/requirement.scss';
import {requirement} from "./requirementData.js";
import { AiOutlineArrowRight } from 'react-icons/ai';

export default function Requirement() {
  return (
    <div className="requirement"  id='section6'>
        <div className="main-title"> Teknik Şartlar ve Sistem Gereksinimleri</div>
        <div className="requirement__list">
       
        {requirement.map((item,index)=>{
          return(
            <div key={index} className="requirement__list__item"> <div><AiOutlineArrowRight /></div>
            <div>{item.value}</div></div>
          )
        })}
        
        </div>
    </div>
  )
}

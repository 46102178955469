import React from 'react'
import './styles/header.scss'
export default function Header({ state }) {
  return (
    <div className='header'>
      <div className='header__content'>
        <figure className='header__content__logo'>
          <img src="assets/images/digitus-logo.png" alt="digitus-logo" />
        </figure>
        {
          state.tus && <div className="header__content__description">TUS Online Eğitim Platformu</div>
        }
        {
          state.dus && <div className="header__content__description">DUS Online Eğitim Platformu</div>
        }
        {
          state.ydus && <div className="header__content__description">YDUS Online Eğitim Platformu</div>
        }
      </div>
    </div>
  )
}


export const tusData = [
    {
        id: 1,
        title: 'Tus Ders Ders Opti Paket',
        topImg: '/assets/images/packet/tus-opti.jpg',
        description: 'Başarıya giden yolda sınırları kaldırın! TUS Ders Ders Opti Paket ile sınırsız, esnek ve özelleştirilmiş eğitim deneyimini keşfedin. 3, 6, 9 ve 12 aylık seçenekler, istediğiniz zaman seyretme imkanı, geniş konu anlatımı, hızlı tekrar..',
        children: [
            {
                img: '/assets/images/packet/tus-opti-3.jpg',
                name: '3 Aylık Opti Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret.<b> (Sınırsız)</b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_opti_paketler.php?parent=eb4c61d1de5ed65848dde3c14227199d',
                videoUrl: 'https://www.youtube.com/embed/2pA5AZEftkM?si=09Og26I6myY72w9Q'
            },

            {
                img: '/assets/images/packet/tus-opti-6.jpg',
                name: '6 Aylık Opti Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret.<b> (Sınırsız)</b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_opti_paketler.php?parent=d46351aa884eb1fd882f2cfc8562811b',
                videoUrl: 'https://www.youtube.com/embed/Q1whNzNNf10?si=BPPfuLOlyYtDuP0G'
            },
            {
                img: '/assets/images/packet/tus-opti-9.jpg',
                name: '9 Aylık Opti Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret.<b> (Sınırsız)</b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_opti_paketler.php?parent=a7bb77dc0735e336b44767eb30d6b97a',
                videoUrl: 'https://www.youtube.com/embed/8UmIzdiiTFg?si=-OW5WKCoowufh672'
            },
            {
                img: '/assets/images/packet/tus-opti-12.jpg',
                name: '12 Aylık Opti Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret.<b> (Sınırsız)</b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_opti_paketler.php?parent=ded6e65604e703ccd37f3063eee76bf6',
                videoUrl: 'https://www.youtube.com/embed/4lHydJOI4bk?si=lSpCH5Z7aTP8QQCW'
            }
        ]
    },

    {
        id: 2,
        title: 'Mezunlara Özel Paketler',
        topImg: '/assets/images/packet/ydus-dahiliye.jpg',
        description: 'Hızlandırılmış Grup: E-Tusdata, Entegre Dersler, Sorularla Tekrar, Tus Soru Çözümü Videoları ve güncel UPDATE içeriğiyle başarıya hız katın. Kısa sürede TUSa hazırlanmanın gücünü keşfedin!',
        children: [
            {
                img: '/assets/images/packet/mezunlara-ozel-65.jpg',
                name: 'HEDEF 65 (HIZLANDIRILMIŞ GRUP)',
                price: '3500',
                description: [
                    'Hızlandırılmış grup <b> (13 Hafta)</b>',
                    'E-Tusdata ve entegre dersler',
                    'Sorularla tekrar <b> (13 Gün) </b>',
                    'Tus soru çözümü videoları',
                    '<b>UPDATE videolar </b> ve yeni notlar',
                ],
                link: 'https://www.tusdataonline.com/tus_kategoriler-2paket.php',
                videoUrl: 'https://www.youtube.com/embed/J96fJpomUrw?si=V0lz_SisGqwZ4bl4'
            },

            {
                img: '/assets/images/packet/mezunlara-ozel-70.jpg',
                name: 'HEDEF 70 (UZUN GRUP)',
                price: '3500',
                description: [
                    'Uzun grup <b>(24 Hafta) </b>',
                    'E-Tusdata ve entegre dersler',
                    'Hedef 70 soru kampı <b> (24 Gün) </b>',
                    'Tus soru çözümü videoları',
                    '<b>UPDATE videolar </b> ve yeni notlar',
                ],
                link: 'https://www.tusdataonline.com/tus_kategoriler-2paket.php',
                videoUrl: 'https://www.youtube.com/embed/_NMSzcRUYCY?si=xkjz_J5woy2GDyt1'
            }
        ]
    },

    {
        id: 3,
        title: 'Branş Branş MEGA Paketler',
        topImg: '/assets/images/packet/mega-paket.jpg',
        description: 'Branş Branş Mega Paket ile başarıya adım atın! Esnek seçenekler: 3, 6, 9 ve 12 ay. Sınırsız erişim, geniş konu anlatımı, hızlı tekrar, Tus Soru Kampı ve istediğiniz eğitmenle başlangıç. Sınavı kazanmak için hazır mısınız?',
        children: [
            {
                img: '/assets/images/packet/tus-brans-3.jpg',
                name: '3 Aylık Mega Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b>(Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_mega_paketler.php?parent=6c6645952a08633e5a5c03dc0dd7f335',
                videoUrl: 'https://www.youtube.com/embed/zzsGu2zgYW0?si=4j5GXHJFvTTlwAGr'
            },

            {
                img: '/assets/images/packet/tus-brans-6.jpg',
                name: '6 Aylık Mega Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b>(Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_mega_paketler.php?parent=95e779a211150a5b7d1fa5dc0afce51b',
                videoUrl: 'https://www.youtube.com/embed/0eQZA3nvR3k?si=yEN41jhHglQgbcjm'
            },
            {
                img: '/assets/images/packet/tus-brans-9.jpg',
                name: '9 Aylık Mega Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b>(Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_mega_paketler.php?parent=c4c68bfed7576489f30a737590ebe06e',
                videoUrl: 'https://www.youtube.com/embed/35Kg8MpfRNY?si=UJUCT2k96Ltof2aL'
            },
            {
                img: '/assets/images/packet/tus-brans-12.jpg',
                name: '12 Aylık Mega Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b>(Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    '<b>Tüm TUS </b> soru kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/tus_mega_paketler.php?parent=af07cb4533329301348ca9085e7894e0',
                videoUrl: 'https://www.youtube.com/embed/u23iFwLxLwI?si=a8KA9-NNROnduy80'
            }
        ]
    },

    {
        id: 4,
        title: 'Komite ve Final Paketleri',
        topImg: '/assets/images/packet/komite.jpg',
        description: 'Komite ve Final Paketleriyle başarıya giden yolu keşfedin! Baştan sona konu anlatımı, komitelere özel hızlı tekrar ve soru çözümleri. Final dönemi dahil, sınavı en etkili şekilde hazırlanın! ',
        children: [
            {
                img: '/assets/images/packet/komite-donem2.jpg',
                name: 'Dönem 2 Komitelere Yönelik Online Dersler',
                price: '3500',
                description: [
                    '<b>Baştan sona </b> konu anlatımı',
                    '<b>Komitelere özel </b> hızlı tekrar',
                    '<b>Komitelere özel </b> soru çözümleri',
                    'Final dönemi dahil',

                ],
                link: 'https://www.tusdataonline.com/komitelere-yonelik-prospektus-serisi-ile-dersler.php',
                videoUrl: 'https://www.youtube.com/embed/Vm8zoWMsk4s?si=hbLNawtlv2vgExns'
            },

            {
                img: '/assets/images/packet/komite-donem3.jpg',
                name: 'Dönem 3 Komitelere Yönelik Online Dersler',
                price: '3500',
                description: [
                    '<b>Baştan sona </b> konu anlatımı',
                    '<b>Komitelere özel </b> hızlı tekrar',
                    '<b>Komitelere özel </b> soru çözümleri',
                    'Final dönemi dahil',

                ],
                link: 'https://www.tusdataonline.com/donem-ucler-icin-komitelere-yonelik-prospektus-serisi-ile-dersler.php',
                videoUrl: 'https://www.youtube.com/embed/krdA6pQYVpQ?si=6O3VNJXDxHtvdfzB'
            },
            {
                img: '/assets/images/packet/komite-hızlı-tekrar.jpg',
                name: 'Dönem 3 Final için Hızlı Tekrar Video Paketi',
                price: '3500',
                description: [
                    '<b>90 gün açık </b> ve <b> sınırsız </b> izleyebilme imkanı',
                    'Komite konularının en az <b> %80 </b> ini kapsar ve  <b> en önemlisini </b> öğrenirsiniz',
                    'İsterseniz  <b> ders ders </b>, isterseniz  <b> komite komite </b> birleştirerek seyredebilirsiniz',

                ],
                link: 'https://www.tusdataonline.com/donem-3-final-hizli-tekrar-video-paketi.php',
                videoUrl: 'https://www.youtube.com/embed/i0SAtf5nDD4'
            }
        ]
    },

    {
        id: 5,
        title: 'Kamplar',
        topImg: '/assets/images/packet/kamplar.jpg',
        description: 'Baştan sona konu anlatımı, komitelere özel hızlı tekrar ve soru çözümleri. Detayları muhteşem Ressital Paketlerimiz, Konu Kampı Paketlerimiz, Soru Kampı Paketlerimiz ve UÇEP Kamplarımızda sizi bekliyor.',
        children: [
          

            {
                img: '/assets/images/packet/kamplar.jpg',
                name: 'Konu Kampı Paketlerimiz',
                price: '3500',
                description: [
                    'TUS KAMPI <b> (24 gün)</b> ',
                    'Vaka Kampı + TUS Kampı',
                    'Hızlı Tekrar KAMPI <b> (13 gün)</b> ',


                ],
                link: 'https://www.tusdataonline.com/konu_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/qgGI9A0FzG4?si=--VwdpB5Fue8qqKy'
            },
            {
                img: '/assets/images/packet/kamplar-soru-kampı.jpg',
                name: 'Soru Kampı Paketlerimiz',
                price: '3500',
                description: [
                    'Tüm TUS kampı <b> (24 gün)</b> ',
                    'Vaka kampı <b> (7 gün)</b>',
                    'Hedef 70 kampı <b> (24 gün)</b>',
                    'UÇEP odaklı vaka anlatımları (Temel + Klinik Tüm Branşlar)',

                ],
                link: 'https://www.tusdataonline.com/soru_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/NTVZU-GlXcc?si=q9uXKVC1_gxz9Lzf'
            },
           
        ]
    },

    {
        id: 6,
        title: 'Entegre Dersler',
        topImg: '/assets/images/packet/entegre.jpg',
        description: 'Entegre Temel Derslerimizle başarıyı yakalayın! Her video için 3 kez izleme hakkı, 1 hafta sistemde kalma süresi ve ders seçme özgürlüğü. Eğitimi kişiselleştirin, başarıyı garantileyin! ',
        children: [
            {
                img: '/assets/images/packet/entegre-endokrin.jpg',
                name: 'Entegre Temel Endokrin',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/nYKP2LsEy8E?si=C7U68GIGuCduWgPl'
            },

            {
                img: '/assets/images/packet/entegre-enfeksiyon.jpg',
                name: 'Entegre Enfeksiyon',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/ZhhsOQA1JhI?si=39lEG_hehDywSXRL'
            },
            {
                img: '/assets/images/packet/entegre-temel-hematoloji.jpg',
                name: 'Entegre Temel Hematoloji',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/TWiZqsmFqJc?si=QUVuB5Erz5FSgOOY'
            },
            {
                img: '/assets/images/packet/entegre-hücre.jpg',
                name: 'Entegre Hücre',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/ejaxPYdgRPU?si=M10XZ4ZkSPRNz5BR'
            },
            {
                img: '/assets/images/packet/entegre-temel-klinik.jpg',
                name: 'Entegre Temel ve Klinik İmmünoloji',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/Pod0_uigg0E?si=TYNjo04jM83vyZGX'
            },
            {
                img: '/assets/images/packet/entegre-gast.jpg',
                name: 'Entegre Temel Gastrointestinal Sistem',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/taLPnZ9c_IE?si=zORyzYqkZMvG8245'
            },
            {
                img: '/assets/images/packet/entegre-hepat.jpg',
                name: 'Entegre Temel Hepatopankreatikobiliyer Sistem',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/oJ4i2k4yA6Y?si=0Si4XApGk_XkC8Dc'
            },
            {
                img: '/assets/images/packet/entegre-temel-solunum.jpg',
                name: 'Entegre Temel Solunum Sistemi',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/bafMIvwG9ds?si=-0gNDGIarxc32Xt-'
            },
            {
                img: '/assets/images/packet/entegre-temel-solunum.jpg',
                name: 'Entegre Temel Üriner Sistem',
                price: '3500',
                description: [
                    'Entegre temel derslerimizden kendi dersini seç',
                    'Her bir video için <b> 3 kez </b> izlenme hakkı',
                    '<b>1 hafta </b> sistemde kalma süresi',
                    'Kendi dersini <b> seçme </b> imkanı',
                ],
                link: 'https://www.tusdataonline.com/entegre_temel_dersler.php',
                videoUrl: 'https://www.youtube.com/embed/OfbpC6gPm0k?si=6f7OKagD_-YTisNI'
            },
        ]
    },

]

export const dusData = [
    {
        id: 1,
        title: 'Mezunlara Özel Paketler',
        topImg: '/assets/images/packet/dus-mezunlar.jpg',
        description: 'DUS Mezunlara Özel Paketlerle hedeflerinizi gerçekleştirin! Konu anlatımı, online DUS videolu çözümler, branş branş soru çözüm videoları, dilediğiniz eğitmeni seçme özgürlüğü. Başarıyı yakalamak için hazır mısınız?',
        children: [
          

            {
                img: '/assets/images/packet/optimum-paket.jpg',
                name: 'Optimum Paket',
                price: '3500',
                description: [
                    'Konu anlatımı (+ ders notu hariç)',
                    'Online tüm DUS videolu çözümler',
                    'Branş branş soru <b> çözüm videoları </b>',
                    'Tıp Dil(İngilizce) videoları',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_kategoriler-3paket.php',
                videoUrl: 'https://www.youtube.com/embed/ClYpdjmhifY?si=dPX7yXGD77K1kHcy'
            },
            {
                img: '/assets/images/packet/premium-paket.jpg',
                name: 'Premium Paket',
                price: '3500',
                description: [
                    'Online çıkmış DUS (1+2) kampı <b>(14 gün) </b>',
                    'Miadent soru serisi kitapları <b>(14 kitap)</b>',
                    'Online hedef 70 soru kampı',
                    'Online hızlı tekrar kampı <b>(14 gün) </b>',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_kategoriler-3paket.php',
                videoUrl: 'https://www.youtube.com/embed/urPV_5P7EeA?si=WUjAzg-VTxaLovFR'
            },


        ]
    },

    {
        id: 2,
        title: 'Branş Branş MEGA Paket',
        topImg: '/assets/images/packet/dus-mega.jpg',
        description: 'Branş Branş Mega Paketle başarıyı yakalamak için hazır olun! Sınırsız izleme, geniş konu anlatımı, hızlı tekrar kampı, Hedef 70 Soru Kampı ve istediğiniz eğitmenle başlangıç. Hedefe doğru emin adımlarla ilerleyin!',
        children: [
            {
                img: '/assets/images/packet/mega-3.jpg',
                name: '3 Aylık MEGA Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b> (Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    'Hedef <b> 70 Soru </b> Kampı ',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_mega_paketler.php?parent=8f083355e484ef7fa9eda4554360bb3d',
                videoUrl: 'https://www.youtube.com/embed/9N-c-ZwZDFg?si=RkLJgt81glFXysQy'
            },

            {
                img: '/assets/images/packet/mega-6.jpg',
                name: '6 Aylık MEGA Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b> (Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    'Hedef <b> 70 Soru </b> Kampı ',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_mega_paketler.php?parent=95196ecf0781a32f0520cf894e5fa8d4',
                videoUrl: 'https://www.youtube.com/embed/DJkmorptBK0?si=Lr-8-ZMl9XVcfXXD'
            },
            {
                img: '/assets/images/packet/mega-9.jpg',
                name: '9 Aylık MEGA Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b> (Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    'Hedef <b> 70 Soru </b> Kampı ',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_mega_paketler.php?parent=60b41e9c301e0f01769aa978e9597a4b',
                videoUrl: 'https://www.youtube.com/embed/P1QTsReO5wM?si=FHp6kMK2z8ijIRcs'
            },
            {
                img: '/assets/images/packet/mega-12.jpg',
                name: '12 Aylık MEGA Paket',
                price: '3500',
                description: [
                    'İstediğin zaman seyret. <b> (Sınırsız) </b>',
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    'Hedef <b> 70 Soru </b> Kampı ',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı',
                ],
                link: 'https://www.tusdataonline.com/dus_mega_paketler.php?parent=f31eca1e42a7dd4fc393742040dd3c6f',
                videoUrl: 'https://www.youtube.com/embed/7ndEFhh7UzI?si=Qx_e5h0VItp-y1hG'
            },

        ]
    },

    {
        id: 3,
        title: 'Kamplar',
        topImg: '/assets/images/packet/dus-kamplar.jpg',
        description: 'Kamplarımızda 2.200 vaka sorusuyla tam donanımlı bir hazırlık! Sadece 11 günde tamamlanabilir, 2023 Sonbahar DUSa kadar açık. DUSDATAnın efsane hocalarının analitik yaklaşımlarıyla başarıya giden yolda lider olun!',
        children: [
            {
                img: '/assets/images/packet/dus-vaka.jpg',
                name: 'Dus Vaka Kampı',
                price: '3500',
                description: [
                    'Tam <b> 11 </b> günde',
                    '<b>2.200 vaka sorusu </b> eşliğinde',
                    'Online (Sınırsız izleme hakkı)',
                    

                ],
                link: 'https://www.tusdataonline.com/dus-vaka-kampi.php',
                videoUrl: 'https://www.youtube.com/embed/WupFShU9jhQ?si=dHJ0915foOm5dpGv'
            },

            {
                img: '/assets/images/packet/dus-plus-vaka.jpg',
                name: 'Rapidus + Dus Vaka Kampı',
                price: '3500',
                description: [
                    'Tam <b> 15.5 </b> gün',
                    'İstediğin branşı seçme imkanı',
                    'Tam paket alımlarında, kitaplar tarafınıza kargolanacaktır',
                    'Online sistem üzerinden kamp notları pdf olarak indirilebilecektir'

                ],
                link: 'https://www.tusdataonline.com/rapidus-ve-vaka-kampi.php',
                videoUrl: 'https://www.youtube.com/embed/y_XFXjpqseU?si=F0CuLhzlbaP5R7fj'
            },


            {
                img: '/assets/images/packet/dus-plus-vaka.jpg',
                name: 'Dus Vaka + Rapidus Klinik Bilimler Kampı',
                price: '3500',
                description: [
                    'Tam <b> 8 </b> günde',
                    'Online İzleme imkânı',
                    'Videolar sistemde yüklü ve 7/24 açık. (Sınırsız İzleme İmkanı)',
                    'Online sistem üzerinden kamp notları pdf olarak indirilebilecektir',
                    
                ],
                link: 'https://www.tusdataonline.com/dus-vaka-rapidus-klinik-bilimler.php',
                videoUrl: 'https://www.youtube.com/embed/WupFShU9jhQ?si=x6sQT1HfNGlFs4ZN'
            },
            {
                img: '/assets/images/packet/dus-hızlı-tekrar.jpg',
                name: 'Rapidus Kampı',
                price: '3500',
                description: [
                    'Tüm kamp veya branş branş',
                    '3,6,9 ve 12 aylık seçenekler',
                    'Tüm branşları seçebilme imkanı',

                ],
                link: 'https://www.tusdataonline.com/dus_rapidus_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/aP5WKmudDIo?si=d_0p_6WvjhRXHdcQ'
            },
            {
                img: '/assets/images/packet/hedef-70.jpg',
                name: 'Hedef 70 Soru Kampı',
                price: '3500',
                description: [
                    '<b> En güncel </b> çekimler',
                    '3,6,9 ve 12 aylık seçenekler',
                    'Tüm branşları seçebilme imkanı',
                    'Modern formatlarda branş branş <b> 300er soru </b>',

                ],
                link: 'https://www.tusdataonline.com/online_dus_soru_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/R3lhx2qurZs?si=JuJ6c3WWh-SkHE43'
            },
            {
                img: '/assets/images/packet/dus-kamplar.jpg',
                name: 'Tüm DUS Soru Kampı',
                price: '3500',
                description: [
                    '<b> 1 (Çıkmış) + 2 (Çıkacak) </b> formatlı soru kampı',
                    '3,6,9 ve 12 aylık seçenekler',
                    'Tam <b> 14 </b> gün',
                    'Tüm kamp veya branş branş',
                ],
                link: 'https://www.tusdataonline.com/tum_dus_bir_arti_iki_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/pjA6QZ-O1fA?si=NiwKfKO8awWzofJ3'
            },
           
        ]
    },

]

export const ydusData = [
    {
        id: 1,
        title: 'YDUS Pediatri',
        topImg: '/assets/images/packet/ydus-pediatri.jpg',
        description: 'YDUS Sınavı için eksiksiz hazırlık! Pediyatri dersi için konu anlatımı, çıkmış soru kampı, Soru Kampı ve Ahmet Hoca ile Pediyatri Hızlı Tekrar Kampı ile bilgi ve stratejilerinizi güçlendirin. Başarıya giden yolda bizimle adım atın',
        children: [
            {
                img: '/assets/images/packet/ydus-pediatri-konu.jpg',
                name: 'Pediatri Konu Anlatımı',
                price: '3500',
                description: [
                    '<b>Kişiye özel </b> planlama',
                    'VIP whatsapp ve telegram grubu',
                    'e-Tusdata',
                    '<b>Size özel çalışma masası</b>',
                    '<b>Psikolog ve diyetisyen </b> desteği',
                ],
                link: 'https://www.tusdataonline.com/ydus_pediatri_konu_paketi.php',
                videoUrl: 'https://www.youtube.com/embed/KCmQk8z4s6o'
            },

            {
                img: '/assets/images/packet/kamplar-soru-kampı.jpg',
                name: 'Çıkmış Soru Kampı',
                price: '3500',
                description: [
                    '<b>Geniş </b> konu anlatımı',
                    '<b>Hızlı </b> tekrar',
                    'Tüm tus soru kampı',
                    'Sistemde hazır PDFler',
                    'Başlangıçta <b> dilediğiniz eğitmeni</b> seçme imkanı'

                ],
                link: 'https://www.tusdataonline.com/ydus_pediatri_cikmis_soru_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/nLevOVWZvso?si=fk0BkVuSvPsqcaiV'
            },
            {
                img: '/assets/images/packet/kamplar-soru-kampı.jpg',
                name: 'Pediatri Soru Kampı',
                price: '3500',
                description: [
                    'Entegre dersler',
                    'TUS kampı',
                    '<b>Hızlı tekrar </b> kampı',
                    'Hedef <b> 70 soru </b> kampı',
                    'Vaka kampı'
                ],
                link: 'https://www.tusdataonline.com/ydus_pediatri_soru_paketi.php',
                videoUrl: 'https://www.youtube.com/embed/3XDbwGaQ1C4?si=IVRtm7GzT_kDVcki'
            },
            {
                img: '/assets/images/packet/ydus-ahmet-hoca.jpg',
                name: 'Ahmet Hoca ile Pediatri Hızlı Tekrar Kampı',
                price: '3500',
                description: [
                    '<b>Geniş </b>konu anlatımı',
                    '<b>Hızlı </b> tekrar kampı',
                    '<b>Tüm TUS </b> soru kampı',
                    'Hedef <b> 70 soru </b> kampı',
                    'Başlangıçta <b> dilediğiniz eğitmeni </b> seçme imkanı'
                ],
                link: 'https://www.tusdataonline.com/ahmet-hoca-ile-ydus-pediatri-40-saatte-hizli-tekrar-kampi.php',
                videoUrl: 'https://www.youtube.com/embed/vfXHb-j9vNw?si=phPXxjfzTI6dwTe9'
            },

        ]
    },


    {
        id: 2,
        title: 'YDUS Dahiliye',
        topImg: '/assets/images/packet/ydus-dahiliye.jpg',
        description: 'Son YDUS videoları 7/24 açık, online sistemde kamp notları PDF olarak indirilebilir. 10 Online + 5 Mobil Açıklamalı deneme, ilgili ders hoca grubunda birebir soru-cevap imkanı ile başarıya giden yolu keşfedin! ',
        children: [
            {
                img: '/assets/images/packet/ydus-dahiliye.jpg',
                name: 'Dahiliye Konu Kampı',
                price: '3500',
                description: [
                    'Bir önceki yıl YDUS vidolarını şimdiden seyret',
                    'Yeni videolarla <b> 5 kez </b> izleme hakkı',
                    'Videoları <b> hızlandırarak </b> izleme imkanınız olacaktır.',
                    '<b>Psikolog ve diyetisyen desteği</b>',
                ],
                link: 'https://www.tusdataonline.com/ydus_dahiliye_konu_paketi.php',
                videoUrl: 'https://www.youtube.com/embed/cQDCB1vbc8g?si=0bv8_JF32v4bLlVg'
            },

            {
                img: '/assets/images/packet/ydus-dahiliye-cikmis.jpg',
                name: 'Dahiliye Çıkmış Soru Kampı',
                price: '3500',
                description: [
                    '7/24 açık ve online izleme imkanı ( Sınırsız )',
                    'Çıkmış YDUS soru kampı <b> hediye </b>',
                    'Tüm <b> ÇIKMIŞ YDUS + ÇIKMIŞ TUS SORU KAMPI </b> sorularının açıklamalı ve analitik çözümü'

                ],
                link: 'https://www.tusdataonline.com/ydus_dahiliye_cikmis_soru_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/Hh4T4s4_uwg?si=bhikB3OxvuSF_oBs'
            },
            {
                img: '/assets/images/packet/ydus-dahiliye-soru.jpg',
                name: 'Dahiliye Soru Kampı',
                price: '3500',
                description: [
                    '<b>2250 soru </b> ve detaylı analizi ile full hazırlık',
                    'Son YDUS videoları hazır ve 7/24 açık',
                    'Online sistem üzerinden <b> kamp notları pdf olarak </b> indirilebilecektir.',
                    '10 adet online Açıklamalı <b> (10 Online + 5 Mobil) </b> deneme',
                    'İlgili dersin hoca grubunda <b> birebir SORU-CEVAP </b> imkanı'
                ],
                link: 'https://www.tusdataonline.com/ydus_dahiliye_soru_paketi.php',
                videoUrl: 'https://www.youtube.com/embed/kTCCkw7810w?si=xuQoV8yww7TMXQCI'
            },

        ]
    },

    {
        id: 3,
        title: 'YDUS Genel Cerrahi',
        topImg: '/assets/images/packet/ydus-genel-konu.jpg',
        description: 'Genel Cerrahi için kişiye özel hazırlık! VIP Whatsapp ve Telegram grubu, e-Tusdata erişimi, özel çalışma masası, psikolog ve diyetisyen desteği ile başarıya giden yolda size özel planlama ile rehberlik ediyoruz! ',
        children: [
            {
                img: '/assets/images/packet/ydus-genel-konu.jpg',
                name: 'Genel Cerrahi Konu Kampı',
                price: '3500',
                description: [
                    '<b>Kişiye özel </b> planlama',
                    'VIP whatsapp ve telegram grubu',
                    'e-Tusdata',
                    'Size özel <b> çalışma masası</b>',
                    '<b>Psikolog ve diyetisyen desteği</b>',
                ],
                link: 'https://www.tusdataonline.com/ydus_genelcerrahi_konu_paketi.php',
                videoUrl: 'https://www.youtube.com/embed/GUSVELq1-TY?si=bvgCYjTFZ526ivyV'
            },

            {
                img: '/assets/images/packet/ydus-genel-soru.jpg',
                name: 'Genel Cerrahi Soru Kampı',
                price: '3500',
                description: [
                    '<b>YAKINDA</b>',


                ],
                link: '',
                videoUrl: 'https://www.youtube.com/embed/GUSVELq1-TY?si=bvgCYjTFZ526ivyV'
            },
            {
                img: '/assets/images/packet/online-deneme.jpg',
                name: 'Online Deneme',
                price: '3500',
                description: [
                    '<b>YAKINDA</b>',

                ],
                link: '',
                videoUrl: 'https://www.youtube.com/embed/GUSVELq1-TY?si=bvgCYjTFZ526ivyV'
            },

        ]
    },

    {
        id: 4,
        title: 'YDUS Kadın Doğum',
        topImg: '/assets/images/packet/kamplar-soru-kampı.jpg',
        description: 'Başarıya giden yolda size özel planlama ile rehberlik ediyoruz! Alanında uzman eğitmenlerle detaylı analiz ve çözüm odaklı derslerle en iyi performansınızı sergileyin. Kendi başarı hikayenizi yazmaya hazır olun! ',
        children: [
            {
                img: '/assets/images/packet/bir-cikti-bir-cikacak.jpg',
                name: 'Bir Çıktı Bir Çıkacak Kampı',
                price: '3500',
                description: [
                    'Yaklaşık <b> 2000 soru </b> ve <b>detaylı analizi </b> ile full hazırlık',
                    'Videolar ilk YDUS a kadar açık',
                    'Windows ve MAC bilgisayarlarda online izleme imkanı',
                    'Tüm TUS soru kampı <b> HEDİYE </b>',
                    'Videoları <b> hızlandırarak </b> izleme imkanını',
                ],
                link: 'https://www.tusdataonline.com/Ydus_Kadin_dogum_bircikti_bircikacak_kamp.php',
                videoUrl: 'https://www.youtube.com/embed/t2GFzz86dAM?si=a3WRU7Go7hWpbod6'
            },

            {
                img: '/assets/images/packet/entegre-enfeksiyon.jpg',
                name: 'Sorularla Entegre Algoritmik Kamp',
                price: '3500',
                description: [
                    'Binlerce soru ve <b> detaylı analizi </b> ile full hazırlık',
                    'Kayıt olanlara çıktı çıkacak kampı <b> %50 indirimli </b>',
                    'Windows ve MAC bilgisayarlarda online izleme imkanı',
                    'Son YDUS videoları zatenhazır ve <b> 7 / 24 </b> açık',


                ],
                link: 'https://www.tusdataonline.com/Ydus_kadin_dogum_Sorularla_entegre_algoritmik_kampi.php',
                videoUrl: 'https://www.youtube.com/embed/jrIMhfBDVhQ?si=kXcf_nTt1urEjFQC'
            },

        ]
    },

]
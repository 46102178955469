import React, { useContext, useEffect } from 'react';
import './styles/popup.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { GeneralContext } from '../../context/generalContext.js';

export default function Popup() {
    const { popup: [isOpen, setIsOpen], popupURL: [popupUrl] } = useContext(GeneralContext);

   
    useEffect(() => {
        if (!isOpen) {
            const iframe = document.querySelector(".popup__content__video");
            if (iframe) {
                iframe.src = "";
            }
        }
    }, [isOpen]);

    return (
        <div className={`popup ${isOpen ? 'showPopup' : ''}`}>
            <span className='popup-close' onClick={() => setIsOpen(false)}>
                <AiOutlineClose />
            </span>
            <div className='popup__container'>
                <div className='popup__content'>
                    <iframe 
                        className='popup__content__video' 
                        width="850" 
                        height="638" 
                        src={isOpen ? popupUrl : ""} 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="autoplay;"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

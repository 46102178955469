import React, { useState, useEffect } from 'react'
import './styles/navbar.scss'
import { Link, animateScroll as scroll } from 'react-scroll';
import { GrPrevious } from 'react-icons/gr';
import { RxHamburgerMenu } from 'react-icons/rx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import "../../components/application/styles/application.scss"
import { IoIosClose } from "react-icons/io";

import { FaDownload, FaWindows, FaApple } from "react-icons/fa6";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function Navbar({ setSituation }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [scrollY, setScrollY] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const closeNavbar = () => {
    setIsChecked(false);
  }

  useEffect(() => {
    // Sayfa scroll olayını dinle
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <input type='checkbox' id='nav-hamburger-menu' hidden checked={isChecked} />
      <div className={`navbar flex-layout ${scrollY > 30 ? 'bg-white' : 'bg-transparent'}`}>
        <div className='navbar__left'>
          <div className={`navbar-previous ${scrollY > 30 ? 'bg-white-icon' : 'bg-transparent-icon'}`} onClick={() => { setSituation(false) }}>

            <GrPrevious />
          </div>
          <div className='navbar__logo'>
            {scrollY > 30 ? <img src="/assets/images/DigiTUS-logo(2).png" alt="" /> : <img src="/assets/images/DigiTUS-logo.png" alt="" />}

          </div>
        </div>

        <label onClick={() => setIsChecked(!isChecked)} className={`hamburger-menu ${scrollY > 30 ? 'bg-black' : ''}`} > <RxHamburgerMenu /> </label>

        <ul className='navbar__list'>
          <li className='navbar__list__item navbar__list__item--red'>
            <Link onClick={handleOpen}

            >
              <figure>
                <img src="assets/images/down-btn.png" alt="Google Play" />
              </figure>
            </Link>
          </li>
          <li className='navbar__list__item' >
            <Link to="section1" onClick={closeNavbar}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Paketler
            </Link>
          </li>
          {/* <li className='navbar__list__item'>
            <Link onClick={closeNavbar}
              to="section2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Avantajlarımız
            </Link>
          </li> */}
          {/* <li className='navbar__list__item'>
            <Link onClick={closeNavbar}
              to="section3"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Cortex Farkı
            </Link>
          </li> */}

          <li className='navbar__list__item'>
            <Link onClick={closeNavbar}
              to="section4"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Ne Zaman Biter?
            </Link>
          </li>
          <li className='navbar__list__item'>
            <Link onClick={closeNavbar}
              to="section5"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              S.S.S
            </Link>
          </li>
          <li className='navbar__list__item'>
            <Link onClick={closeNavbar}
              to="section6"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Sistem Gereksinimleri
            </Link>
          </li>

        </ul>

      </div >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >


        <Box sx={style}>
          <Typography id="modal-modal-title" className='application__title main-title' variant="h6" component="h2">
            <figure>
              <img src="assets/images/down-btn.png" alt="Google Play" />
            </figure>
          </Typography>
          <div onClick={handleClose}>
            <IoIosClose className='close-icon' />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <div className="app-item windows-card">
                      <div className="app-item__container">
                        <div className="app-item__container__figure desktop-app-figure">
                          <figure>
                            <img src="assets/images/windows-colorful-icon.png" alt="Google Play" />
                          </figure>
                        </div>
                        <div className="app-item__content">
                          <div className="app-item__content__info">
                            <h4 className="app-item__content__title">Windows Versiyonu</h4>
                          </div>
                          <a className="windows-version-btn desktop-application-btn" target="_blank" href="https://uicontent.digitusegitim.com/program/windows/korteksv1.msi">
                            <span>İndir</span>
                            <FaDownload />
                          </a>

                        </div>
                      </div>

                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <div className="app-item mac-card">
                      <div className="app-item__container">
                        <div className="app-item__container__figure desktop-app-figure mac-version">
                          <figure>
                            <img src="assets/images/mac-colorful-icon.png" alt="Google Play" />
                          </figure>
                        </div>
                        <div className="app-item__content">
                          <div className="app-item__content__info">
                            <h4 className="app-item__content__title">MacBook Versiyonu</h4>
                          </div>
                          <a className="mac-version-btn desktop-application-btn" target="_blank" href="https://uicontent.digitusegitim.com/program/mac/korteksv1.dmg">
                            <span>İndir</span>
                            <FaDownload />
                          </a>
                          {/* <div className="app-item__info">
                                                        <a href="https://cdn.medotomy.com/site/document/mac_kurulum.pdf" target="_blank">Mac kurulum dokümantasyonu için <span> tıklayınız. </span></a>
                                                    </div> */}
                        </div>
                      </div>

                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <div className="app-item">
                      <div className="app-item__header">Mobil ve Tabletler için Cortex</div>
                      <div className="row d-flex justify-content-center g-3">
                        <Grid className="mobile-application-center" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={12} sm={4}>
                            <Item>
                              <a href="https://play.google.com/store/apps/details?id=com.tusdata.digitus" target="_blank" className="app-item__footer app-item__footer--desktop d-none">
                                <figure ><img src="assets/images/play.png" alt="Google Play" /></figure>
                                <div className="app-item__footer__text">
                                  <div className="app-item__footer__text--app">Google Play</div>
                                </div>
                              </a>
                              <a href="https://play.google.com/store/apps/details?id=com.tusdata.digitus" target="_blank" className="app-item__footer app-item__footer--mobile">
                                <div className="desktop-title">ANDROID</div>
                                <figure ><img src="assets/images/google-playqr.png" alt="Google Play QR" /></figure>
                              </a>
                            </Item>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Item>
                              <a href="https://apps.apple.com/us/app/cortex-digitus/id6471902178" target="_blank" className="app-item__footer app-item__footer--desktop d-none">
                                <figure ><img src="assets/images/app.png" alt="App Store" /></figure>
                                <div className="app-item__footer__text">
                                  <div className="app-item__footer__text--app">App Store</div>
                                </div>
                              </a>
                              <a href="https://apps.apple.com/us/app/cortex-digitus/id6471902178" target="_blank" className="app-item__footer app-item__footer--mobile">
                                <div className="desktop-title">IOS</div>
                                <figure ><img src="assets/images/ıosqr.png" alt="App Store QR" /></figure>
                              </a>
                            </Item>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export const duration = [
    {
        name: 'Anatomi',
        img: '/assets/images/branch/anatomi.jpg',
        durationData: 197,
        topicCount: 9,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Fizyoloji',
        img: '/assets/images/branch/fizyoloji.jpg',
        durationData: 273,
        topicCount: 12,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Biyokimya',
        img: '/assets/images/branch/biyokimya.jpg',
        durationData: 311,
        topicCount: 9,
        category: 'tus',
        type: "Temel"
    },

    {
        name: 'Mikrobiyoloji',
        img: '/assets/images/branch/mikrobiyoloji.jpg',
        durationData: 334,
        topicCount: 7,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Patoloji',
        img: '/assets/images/branch/patoloji.jpg',
        durationData: 300,
        topicCount: 24,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Farmakoloji',
        img: '/assets/images/branch/farmakoloji.jpg',
        durationData: 363,
        topicCount: 14,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Dahiliye',
        img: '/assets/images/branch/dahiliye.jpg',
        durationData: 491,
        topicCount: 10,
        category: 'tus',
        type: "Klinik"
    },
    {
        name: 'Pediatri',
        img: '/assets/images/branch/pediatri.jpg',
        durationData: 443,
        topicCount: 18,
        category: 'tus',
        type: "Klinik"
    },
    {
        name: 'Genel Cerrahi',
        img: '/assets/images/branch/cerrahi.jpg',
        durationData: 221,
        topicCount: 34,
        category: 'tus',
        type: "Klinik"
    },
    {
        name: 'Küçük Stajlar',
        img: '/assets/images/branch/kucuk_stajlar.jpg',
        durationData: 228,
        topicCount: 152,
        category: 'tus',
        type: "Temel"
    },
    {
        name: 'Kadın Doğum',
        img: '/assets/images/branch/kadin_dogum.jpg',
        durationData: 225,
        topicCount: 3,
        category: 'tus',
        type: "Klinik"
    },
    {
        name: 'Anatomi',
        img: '/assets/images/branch/anatomi.jpg',
        durationData: 98,
        topicCount: 12,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Fizyoloji',
        img: '/assets/images/branch/fizyoloji.jpg',
        durationData: 154,
        topicCount: 12,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Biyokimya',
        img: '/assets/images/branch/biyokimya.jpg',
        durationData: 123,
        topicCount: 9,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Mikrobiyoloji',
        img: '/assets/images/branch/mikrobiyoloji.jpg',
        durationData: 117,
        topicCount: 6,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Patoloji',
        img: '/assets/images/branch/patoloji.jpg',
        durationData: 136,
        topicCount: 25,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Farmakoloji',
        img: '/assets/images/branch/farmakoloji.jpg',
        durationData: 113,
        topicCount: 14,
        category: 'dus',
        type: "Temel"
    },
    {
        name: 'Protetik Diş Tedavisi',
        img: '/assets/images/branch/protetik.jpg',
        durationData: 185,
        topicCount: 8,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Restoratif Diş Tedavisi',
        img: '/assets/images/branch/restoratif.jpg',
        durationData: 88,
        topicCount: 11,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Ağız, Diş ve Çene Cerrahisi',
        img: '/assets/images/branch/agiz_cerrahi.jpg',
        durationData: 166,
        topicCount: 7,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Ağız, Diş ve Çene Radyolojisi',
        img: '/assets/images/branch/agiz_radyoloji.jpg',
        durationData: 95,
        topicCount: 16,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Periodontoloji',
        img: '/assets/images/branch/periodontoloji.jpg',
        durationData: 117,
        topicCount: 7,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Endodonti',
        img: '/assets/images/branch/endodonti.jpg',
        durationData: 103,
        topicCount: 6,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Ortodonti',
        img: '/assets/images/branch/ortodonti.jpg',
        durationData: 94,
        topicCount: 13,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Pedodonti',
        img: '/assets/images/branch/pedodonti.jpg',
        durationData: 108,
        topicCount: 29,
        category: 'dus',
        type: "Klinik"
    },
    {
        name: 'Pediatri',
        img: '/assets/images/branch/Pediatri.jpg',
        durationData: 467,
        topicCount: 40,
        category: 'ydus',
    },
    {
        name: 'Dahiliye',
        img: '/assets/images/branch/dahiliye.jpg',
        durationData: 352,
        topicCount: 25,
        category: 'ydus',
    },

   
    {
        name: 'Kadın Doğum',
        img: '/assets/images/branch/kadin_dogum.jpg',
        durationData: 298,
        topicCount: 7,
        category: 'ydus',
    },
    {
        name: 'Genel Cerrahi',
        img: '/assets/images/branch/Cerrahi.jpg',
        durationData: 102,
        topicCount: 36,
        category: 'ydus',
    },
 
  
]
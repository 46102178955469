import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { HiCursorClick } from 'react-icons/hi';
import { useContext } from 'react';
import { GeneralContext } from '../../context/generalContext';
import Parser from 'html-react-parser';

export default function PackageItem({ img, name, price, description, videoUrl, link }) {
  const { popup: [isOpen, setIsOpen], popupURL: [, setPopupUrl] } = useContext(GeneralContext);

  const handleClickVideo = (e) => {
    setIsOpen(true);
    setPopupUrl(e.target.dataset.videourl);
  };


  const containsYakinda = description.some(item => item.includes('YAKINDA'));

  return (
    <div className='package-container'>
      <div className='package'>
        <figure className='package__figure'>
          <img src={img} alt='package-img' />
        </figure>
        <div className="package__name"> <a href={link} target='_blank'>{name}</a> </div>
        {/* <div className="package__price">{price} ₺</div> */}
        <div className="package__property">
          {description.map((item, index) => (
            <div key={index} className="package__property__item">
              <span><AiOutlineArrowRight /></span>
              <span>{Parser(item)}</span>
            </div>
          ))}
        </div>
        {/* "Satın Al" butonunu conditionally gizliyoruz */}
        <div className={`package__buy ${containsYakinda ? 'hidden' : ''}`}>
          <div>
            <a target='_blank' rel="noreferrer" href={link}>Satın Al</a>
          </div>
        </div>
        <div className='package__example-video' onClick={handleClickVideo}>
          <span data-videourl={videoUrl}><HiCursorClick />Örnek video izlemek için tıklayınız</span>
        </div>
      </div>
    </div>
  );
}

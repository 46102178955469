import { useState } from 'react';
import './styles/chat.scss';

export default function Chat() {
  const [isClose, setIsClose] = useState(false);
  return (
    <div className="trwpwhatsappall alc">
      {
        isClose &&
        <div className="trwpwhatsappballon">
          <span id="kapatac" className="kapat" onClick={() => setIsClose(false)}>X</span>
          <div className="trwpwhatsapptitle">
          Cortex Destek
          </div>

          <div className="trwpwhatsappinput">
            <form action="https://web.whatsapp.com/send?" method="get" target="_blank">
              <iframe title='chat-online' src="https://tusdataonline.com/Online_Destek/index.html" style={{ height: "400px", border: "none" }}></iframe>

              <div style={{ paddingBottom: "10px", height: "10px" }}></div>
            </form>
          </div>
        </div>
      }
      <div id="ackapa" className="trwpwhatsappbutton" onClick={() => setIsClose(!isClose)}>
        <i className="fa fa-whatsapp"></i> <span>Cortex Destek</span>
      </div>
    </div>
  )
}

import './styles/startedPage.scss';
export default function StartedPage({ dispatch, setSituation }) {

    const changePackageStatus = (e) => {
        dispatch({ type: e.target.dataset.name });
        setSituation(true);
    };

    const handleOn = (e) => {
        const item = e.currentTarget;
        item.classList.add('started-page__item--active');

        const pageItems = document.querySelectorAll('.started-page__item');
        pageItems.forEach((otherItem) => {
            if (otherItem !== item) {
                otherItem.classList.remove('started-page__item--active');
                otherItem.classList.add('started-page__item--passive');
            }
        });
    };

    const handleOff = (e) => {
        const item = e.currentTarget;
        item.classList.remove('started-page__item--active');

        const pageItems = document.querySelectorAll('.started-page__item');
        pageItems.forEach((otherItem) => {
            if (otherItem !== item) {
                otherItem.classList.remove('started-page__item--passive');
            }
        });

    };

    return (
        <div className='started-container'>
            <a href="https://www.tusdata.com/" target='_blank'><figure className='started-page-logo-1'><img src="assets/images/digitus-logo(2).png" alt="digitus-logo" /></figure> </a>
            <a href="https://www.tusdata.com/" target='_blank'><figure className='started-page-logo-2'><img src="assets/images/tusdata_header_logo.png" alt="tusdata-logo" /></figure> </a>
            <div className='started-page-choose-type main-title'><span>Online Eğitim Platformu</span> </div>
            <div className='started-page'>

                <div className='started-page__item started-page__item--tus' onMouseEnter={handleOn} onMouseLeave={handleOff}>
                    <figure className='started-page__item__logo'><img src="assets/images/tus.png" alt="started-page-1" /></figure>
                    <div className='started-page__item__header'>TUS</div>
                    <div className='started-page__item__subtitle'>Online Eğitim Platformu</div>
                    <div className='started-page-button'><button data-name='tus' onClick={changePackageStatus}>İncele</button></div>
                </div>

                <div className='started-page__item started-page__item--dus' onMouseEnter={handleOn} onMouseLeave={handleOff}>
                    <figure className='started-page__item__logo'><img src="assets/images/dus.png" alt="started-page-1" /></figure>
                    <div className='started-page__item__header'>DUS</div>
                    <div className='started-page__item__subtitle'>Online Eğitim Platformu</div>
                    <div className='started-page-button'><button data-name='dus' onClick={changePackageStatus}>İncele</button></div>
                </div>

                <div className='started-page__item started-page__item--ydus' onMouseEnter={handleOn} onMouseLeave={handleOff}>
                    <figure className='started-page__item__logo'><img src="assets/images/ydus.png" alt="started-page-1" /></figure>
                    <div className='started-page__item__header'>YDUS</div>
                    <div className='started-page__item__subtitle'>Online Eğitim Platformu</div>
                    <div className='started-page-button'><button data-name='ydus' onClick={changePackageStatus}>İncele</button></div>
                </div>
            </div>
        </div>
    )
}



import React, { useEffect, useState } from "react";
import PackageItem from "./PackageItem.jsx";
import { tusData, dusData, ydusData } from "./packageData.js";
import "./styles/package.scss";
import { motion } from "framer-motion";
import { GrFormPrevious } from 'react-icons/gr';



export default function Package({ state, setIsOpen }) {
  const [idCount, setIdCount] = useState(null);
  const [childPackage, setChildPackage] = useState(false);
  const selectPackage = (e) => {
    setIdCount(e.target.dataset.id);

    setChildPackage(true);
  }
  console.log("aaaaaaa" + dusData.children)
  return (
    <motion.div id="section1" initial={{ opacity: 0, scale: 0.5 }}

      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}>
      {
        state.tus && (
          <>
            <div className="main-title">TUS Paketler</div>
            {
              childPackage &&
              <div className="sub-title">
                <span className="close-child" onClick={() => { setChildPackage(false) }}><GrFormPrevious /> <span>Tüm Paketler</span>  </span>
                {
                  childPackage && tusData.map(item => (item.id === parseInt(idCount) && item.title))
                }
              </div>
            }

          </>)
      }
      {
        state.dus && (
          <>
            <div className="main-title">DUS Paketler</div>
            {
              childPackage &&
              <div className="sub-title">
                <span className="close-child" onClick={() => { setChildPackage(false) }}><GrFormPrevious /><span>Tüm Paketler</span> </span>
                {
                  childPackage && dusData.map(item => (item.id === parseInt(idCount) && item.title))
                }
              </div>
            }
          </>)
      }
      {
        state.ydus && (
          <>
            <div className="main-title">YDUS Paketler</div>
            {
              childPackage &&
              <div className="sub-title">
                <span className="close-child" onClick={() => { setChildPackage(false) }}><GrFormPrevious /><span>Tüm Paketler</span> </span>
                {
                  childPackage && ydusData.map(item => (item.id === parseInt(idCount) && item.title))
                }
              </div>
            }
          </>)
      }

      {
        state.tus && !childPackage &&
        <>
          <div className="package__category">
            {
              tusData.map((item, index) => (
                <div className="package__category__item" key={item.id}>
                  <figure className="package__category__item__photo">
                    <img src={item.topImg} alt="category" />
                  </figure>
                  <div className="package__category__item__content">
                    <h3 className="title">{item.title}</h3>
                    <p className="description">{item.description}</p>
                    <button onClick={selectPackage} data-id={item.id} className="button">İncele</button>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      }

      {
        state.dus && !childPackage &&
        <>
          <div className="package__category">
            {
              dusData.map((item, index) => (
                <div className="package__category__item" key={item.id}>
                  <figure className="package__category__item__photo">
                    <img src={item.topImg} alt="category" />
                  </figure>
                  <div className="package__category__item__content">
                    <h3 className="title">{item.title}</h3>
                    <p className="description">{item.description}</p>
                    <button onClick={selectPackage} data-id={item.id} className="button">İncele</button>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      }

      {
        state.ydus && !childPackage &&
        <>
          <div className="package__category">
            {
              ydusData.map((item, index) => (
                <div className="package__category__item" key={item.id}>
                  <figure className="package__category__item__photo">
                    <img src={item.topImg} alt="category" />
                  </figure>
                  <div className="package__category__item__content">
                    <h3 className="title">{item.title}</h3>
                    <p className="description">{item.description}</p>
                    <button onClick={selectPackage} data-id={item.id} className="button">İncele</button>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      }

      {
        state.tus && childPackage &&
        <>
          <div div className={`package__list`}>
            {
              tusData.map((item, index) => (
                item.id === parseInt(idCount) &&
                (tusData[index].children).map((element, index) => (
                  <PackageItem setIsOpen={setIsOpen} key={index} {...element} description={element.description} />
                ))
              ))
            }
          </div>
        </>
      }

      {
        state.dus && childPackage &&
        <>
          <div div className={`package__list`}>
            {
              dusData.map((item, index) => (
                item.id === parseInt(idCount) &&
                (dusData[index].children).map((element, index) => (
                  <PackageItem setIsOpen={setIsOpen} key={index} {...element} description={element.description} />
                ))
              ))
            }
          </div>
        </>
      }

      {
        state.ydus && childPackage &&
        <>
          <div div className={`package__list`}>
            {
              ydusData.map((item, index) => (
                item.id === parseInt(idCount) &&
                (ydusData[index].children).map((element, index) => (
                  <PackageItem setIsOpen={setIsOpen} key={index} {...element} description={element.description} />
                ))
              ))
            }
          </div>
        </>
      }
    </motion.div >
  );
}

import React, { useContext } from 'react'
import { duration } from './duration.js';
import Slider from "react-slick";
import { GeneralContext } from '../../context/generalContext.js';
export default function StepOne({ setPassStepTwo, setSelectLesson, selectLesson }) {

    const { packageCategory: { tus, dus, ydus } } = useContext(GeneralContext);

    const selectItem = (e) => {
        setPassStepTwo(true);
        const { name, time, topic } = e.target.dataset;
        setSelectLesson({
            name,
            time,
            topic
        })
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        onmousedown: false,

        responsive: [
            {
                breakpoint: 1025,
                centerMode: true,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                centerMode: true,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    arrows: false
                }
            },

        ]
    };
    return (
        <>
            <div className="duration__title main-title">Cortex İle Ne Zaman Biter?</div>

            <div className={`duration-first`}>
                <p className="duration__info">Cortex ders videolarının ne zaman biteceğini öğrenmek için lütfen hesaplamak istediğiniz dersi seçiniz</p>

                <div>
                    <div>
                        <div className='duration__select-type'>Temel Dersler</div>
                        <div className="duration__lecture">
                            {
                                tus &&
                                <Slider {...settings}>
                                    {duration.map((item, index) => (
                                        item.category === 'tus' && item.type === 'Temel' &&
                                        <div onClick={selectItem} data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className={`duration__lecture__item ${selectLesson && selectLesson.name === item.name ? 'active' : ""}`} key={index}>
                                            <figure className="duration__lecture__item__logo">
                                                <img data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} src={item.img} alt={item.name} />
                                            </figure>
                                            <div data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className="duration__lecture__item__name">{item.name}</div>
                                        </div>
                                    ))}
                                </Slider>
                            }
                            {
                                dus &&
                                <Slider {...settings}>
                                    {duration.map((item, index) => (
                                        item.category === 'dus' && item.type === 'Temel' &&
                                        <div onClick={selectItem} data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className={`duration__lecture__item ${selectLesson && selectLesson.name === item.name ? 'active' : ""}`} key={index}>
                                            <figure className="duration__lecture__item__logo">
                                                <img data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} src={item.img} alt={item.name} />
                                            </figure>
                                            <div data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className="duration__lecture__item__name">{item.name}</div>
                                        </div>
                                    ))}
                                </Slider>
                            }
                            {
                                ydus &&
                                <Slider {...settings}>
                                    {duration.map((item, index) => (
                                        item.category === 'ydus' &&
                                        <div onClick={selectItem} data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className={`duration__lecture__item ${selectLesson && selectLesson.name === item.name ? 'active' : ""}`} key={index}>
                                            <figure className="duration__lecture__item__logo">
                                                <img data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} src={item.img} alt={item.name} />
                                            </figure>
                                            <div data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className="duration__lecture__item__name">{item.name}</div>
                                        </div>
                                    ))}
                                </Slider>
                            }
                        </div>
                    </div>

                    {
                        ydus || <div>
                            <div className='duration__select-type'>Klinik Dersler</div>
                            <div className="duration__lecture">
                                {
                                    tus &&
                                    <Slider {...settings}>
                                        {duration.map((item, index) => (
                                            item.category === 'tus' && item.type === 'Klinik' &&
                                            <div onClick={selectItem} data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className={`duration__lecture__item ${selectLesson && selectLesson.name === item.name ? 'active' : ""}`} key={index}>
                                                <figure className="duration__lecture__item__logo">
                                                    <img data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} src={item.img} alt={item.name} />
                                                </figure>
                                                <div data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className="duration__lecture__item__name">{item.name}</div>
                                            </div>
                                        ))}
                                    </Slider>
                                }
                                {
                                    dus &&
                                    <Slider {...settings}>
                                        {duration.map((item, index) => (
                                            item.category === 'dus' && item.type === 'Klinik' &&
                                            <div onClick={selectItem} data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className={`duration__lecture__item ${selectLesson && selectLesson.name === item.name ? 'active' : ""}`} key={index}>
                                                <figure className="duration__lecture__item__logo">
                                                    <img data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} src={item.img} alt={item.name} />
                                                </figure>
                                                <div data-name={item.name} data-time={item.durationData} data-topic={item.topicCount} className="duration__lecture__item__name">{item.name}</div>
                                            </div>
                                        ))}
                                    </Slider>
                                }

                            </div>
                        </div>
                    }
                </div>

            </div>

        </>
    )
}

import { useEffect, useState } from "react"
import { motion } from "framer-motion"
export default function StepThree({ selectLesson, setPassStepThree }) {
    const { name, time, topic, selectedTime } = selectLesson;
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const totalTime = Math.ceil(time / parseInt(selectedTime));
        setTotal(totalTime)
    }, [selectLesson])

    return (

        <motion.div initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}>
            <div className="duration__result">
                <div>Seçtiğiniz <span className="bold">{name} </span>dersi için online eğitim platformu <span className="bold">Cortex </span>'te <span className="bold">{topic} </span> adet konu mevcuttur ve toplam <span className="bold">{time}</span> saatlik eğitim içermektedir. </div>
                <div>Günlük ortalama <span className="bold">{selectedTime} </span> saat çalışarak <span className="bold">{name} </span> dersini <span className="bold result">{isNaN(total) ? setPassStepThree(false) : total} </span> günde bitirebilirsiniz.</div>
            </div>
        </motion.div>


    )
}

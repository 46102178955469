import React from 'react'
import { FiTwitter, FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import { AiFillApple, AiOutlineCopyright } from 'react-icons/ai';
import { BsGooglePlay, BsFillPhoneVibrateFill } from 'react-icons/bs';
import { TbWorldCheck } from 'react-icons/tb';
import { BiMailSend } from 'react-icons/bi';
import './styles/footer.scss'
export default function Footer() {
    return (
        <div>
            <div className="footer">
                <div className="footer__up">
                    <div className="footer__info">
                        <img className="footer-digitus" src="assets/images/digitus-logo.png" alt="digitus-logo" />
                        <p>Hedeflerin doğrultusunda sana en uygun çalışma programını sunup çalışmalarını takip ederek istatistikler ve ilerleme durumlarını gösterir. Hedefine ilerleyeceğin bu yolda ders videoları izleyebilir, sorular ve deneme sınavları çözebilirsin. Rehberlik, eğitim koçu  ve çalışma istatistikleri sayesinde hem motivasyonunu  yüksek tutmayı hem de adeta bir mentorlük hizmeti alarak çalışma sürecini en etkin şekilde değerlendirebilirsin.</p>
                    </div>
                    <div className="footer__contact">
                        <h2 className="footer-title">İletişim</h2>
                        <div className="footer__contact__list">
                            <div className="footer__contact__list__item">
                                <a href="https://www.tusdata.com/" target='_blank'> <TbWorldCheck />  <p>www.tusdata.com</p></a>
                            </div>
                            <div className="footer__contact__list__item">
                                <a href="tel:+05511515151">  <BsFillPhoneVibrateFill />  <p>0507 483 88 39</p></a>
                            </div>
                            <div className="footer__contact__list__item">
                                <a href="tel:+905425424242">  <BsFillPhoneVibrateFill />  <p>0551 090 78 51</p></a>
                            </div>
                            <div className="footer__contact__list__item">
                                <a href="https://www.tusdataonline.com/" target='_blank'><BiMailSend />  <p>online@tusdata.com</p></a>
                            </div>
                        </div>
                    </div>
                    <div className="footer__social">
                        <h2 className="footer-title">Sosyal medyada </h2>
                        <div className="footer__social__list">
                            <div className="footer__social__list__item">
                                <a href="https://www.youtube.com/Tusdata" target='_blank'>   <FiYoutube /> </a>
                            </div>
                            <div className="footer__social__list__item">
                                <a href="https://www.instagram.com/tusdata" target='_blank'>  <FiInstagram /> </a>
                            </div>
                            <div className="footer__social__list__item">
                                <a href="https://www.facebook.com/tusdata.com.tr" target='_blank'> <FiFacebook />  </a>
                            </div>
                            <div className="footer__social__list__item">
                                <a href="https://twitter.com/tusdata" target='_blank'>  <FiTwitter />  </a>
                            </div>
                        </div>
                        <div className="footer__nav">
                            <a href="https://apps.apple.com/us/app/cortex-digitus/id6471902178" target='_blank'>
                                <div className="footer__nav__app">
                                    <div className="footer__nav__app__icon">
                                        <AiFillApple />
                                    </div>
                                    <div className="footer__nav__app__text">
                                        App Store
                                    </div>
                                </div>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.tusdata.digitus" target='_blank'>
                                <div className="footer__nav__app">
                                    <div className="footer__nav__app__icon">
                                        <BsGooglePlay />
                                    </div>
                                    <div className="footer__nav__app__text">
                                        Google Play
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer__down">
                    <div className="footer__logo">
                    <a href="https://www.tusdata.com/" target='_blank'>
                        <figure className="footer__logo__item">
                            <img src="/assets/images/tusdata_header_logo.png" alt="" />
                        </figure>
                        </a>
                        <a href="https://www.klinisyen.com/" target='_blank'>
                        <figure className="footer__logo__item">
                            <img src="/assets/images/klinisyen-logo.png" alt="" />
                        </figure>
                        </a>
                        <a href="https://www.dusdata.com/" target='_blank'>
                        <figure className="footer__logo__item">
                            <img src="/assets/images/logo.png" alt="" />
                        </figure>
                        </a>
                   
                    </div>
                    <div className="footer__copyright">
                        <AiOutlineCopyright /><p> TUSDATA firması tarafından üretilmiştir. Tüm hakları TUSDATA'ya aittir.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { AiOutlineRight } from 'react-icons/ai';
import './styles/faq.scss';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<AiOutlineRight sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('');

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (

        <div className='faq' id='section5'>
            <div className="main-title">Sıkça Sorulan Sorular</div>
            <div className='faq__content'>
                <div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='faq__question'>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Cortex online eğitim uygulamasını nasıl indirebilirim ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Sol altta bulunan <span style={{fontWeight: "600"}}> 'Uygulama İndir' </span>  butonuna tıklayarak cihazınıza uygun uygulamayı hızlıca indirebilirsiniz
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='faq__question'>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>Cortex online eğitim uygulamasına aynı anda iki cihazdan giriş yapabilir miyim?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Uygulamayı indirmeden önce kabul ettiğiniz sözleşmelerde, online eğitim uygulamasının yalnızca bir cihazdan kullanılabileceği belirtilmiştir. Ancak, cihaz değişikliği yapmak isterseniz bu talebiniz teknik destek ekibimize iletebilirsiniz.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='faq__question'>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>Cortex online eğitim uygulamasına giriş yaparken sms gelmiyor, ne yapmalıyım ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Operatörünüzle başlıklı SMS alımınızı kontrol edin. Sorun yoksa, Teknik Destek'e telefon numaralarınızın eşleşip eşleşmediğini kontrol etmelerini söyleyin. Eğer numaralar eşleşiyor ve hala SMS alamıyorsanız, teknik destek ekibimiz size yardımcı olacaktır.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='faq__question'>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography>Telefon numaram değişti sms alamıyorum, ne yapmalıyım?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Telefon numarası değişikliği yaptıysanız kişisel bilgilerinizi güncellemek için destek ekimizle iletişime geçebilirsiniz.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className='faq__question'>
                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                            <Typography>Cortex online eğitim uygulamasına giriş yaparken paketini aldıktan sonra kullanıma ne zaman başlayabilirim?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Online eğitim paketi satın alma işleminiz tamamlandıktan ve Cortex ekibi tarafından kontrol edildikten sonra tarafınıza onay SMS'i gönderilecektir. SMS aldıktan sonra kullanıma başlayabilirsiniz.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className='faq__question'>
                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                            <Typography>Mac Bilgisayar kullanıyorum Cortex online eğitim uygulamasını nasıl indirebilirim ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Sol altta bulunan <span style={{fontWeight: "600"}}> 'Uygulama İndir' </span> butonuna tıklayarak açılan pencerede 'MAC Versiyonu' cihazınıza hızlıca indirebilirsiniz.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className='faq__question'>
                        <AccordionSummary aria-controls="panel58-content" id="panel8d-header">
                            <Typography>Playlistte gözüken videolara tıklıyorum ancak uzun süreler beklememe rağmen videolar açılmıyor, neden?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='faq__answer'>
                            <Typography>
                                Videolarımız online olarak size sunulduğu için <span style={{fontWeight: "600"}}> en az 16mb </span>  download hızına sahip bir internet bağlantısına sahip olmanız gerekmektedir. Önerilen ise 32mb download hızıdır. Önerilen hıza sahip bir internetiniz varsa tıkladığınız video size özel olarak hazırlanıp ekranınızda gözükecektir. Daha düşük internet hızlarında ise bu süre internet bağlantınızdan kaynaklanarak uzamaktadır. İnternet hızınızı<a href="https://www.speedtest.net/" target='_blank' > <span style={{fontWeight: "600"}}>  www.speedtest.net </span> </a>adresine girerek test edebilirsiniz. Süperonline ve Vodafonet kullanıcılarından bazılarında internet servis sağlayıcılarından kaynaklı problemler yaşanabilmektedir. Kamu kurum ve kuruluşlarındaki internet kısıtlamalarından dolayı da videolara erişimde problemler yaşayabilirsiniz. Lütfen bu tür durumlarda cep telefonunuzun internetini paylaştırarak test ediniz.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {/* <figure className="faq__figure">
                <img src="/assets/images/faq.png" alt="" />
            </figure> */}
            </div>
        </div>
    );
}

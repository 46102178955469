import React, { useState } from 'react';

import './styles/duration.scss';
import StepOne from './StepOne';
import StepTwo from './StepTwo'
import StepThree from './StepThree'

export default function Duration() {
    const [passStepTwo, setPassStepTwo] = useState(false);
    const [passStepThree, setPassStepThree] = useState(false);
    const [selectLesson, setSelectLesson] = useState(null);

    return (
        <div className="duration" id='section4'>
            <StepOne setPassStepTwo={setPassStepTwo} setSelectLesson={setSelectLesson} selectLesson={selectLesson} />
            {
                passStepTwo && <StepTwo setPassStepThree={setPassStepThree} setSelectLesson={setSelectLesson} />
            }
            {
                passStepThree && <StepThree selectLesson={selectLesson} setPassStepThree={setPassStepThree} />
            }
        </div>
    );
}
